import { LayoutProps } from 'styled-system'

export type ToggleSizeOptions = 'lg' | 'md'

export interface ToggleVariantsProps {
  lg: LayoutProps
  md: LayoutProps
}

export const TOGGLE_BASE_VARIANTS: ToggleVariantsProps = {
  lg: {
    width: '60px',
    height: '30px'
  },
  md: {
    width: '48px',
    height: '24px'
  }
}

export const TOGGLE_SLIDER_VARIANTS: ToggleVariantsProps = {
  lg: {
    width: '20px',
    height: '20px'
  },
  md: {
    width: '18px',
    height: '18px'
  }
}
