import { CombinedTextProps } from '@dtx-company/shared-components/src'
import { DEFAULT_COLOR, defaultUserThemeInput } from '@dtx-company/true-common/src/constants/page'
import { FLOWCODE_ROOT } from '@dtx-company/true-common/src/constants/root'
import { FLOWPAGE_API_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { LinkProvider, fullUrlPattern } from '@dtx-company/inter-app/src/constants/linkTypes'
import { getApiRootUrl } from '@dtx-company/true-common/src/utils/urls/services'
import { getFlowpageHost } from '@dtx-company/true-common/src/utils/urls/host'

export * from './homepage'

export const OPT_OUT = 'OPT_OUT'

export const flowpageRoot = getFlowpageHost() + '/'
export const flowpageUrlRoot = `${process.env.PROTOCOL}://${flowpageRoot}`

export const appleMobileDevices = new Set(['iPhone', 'iPad', 'iPod'])

export const gqlApiUrl = `${FLOWPAGE_API_ENDPOINT}/graphql`
export const profileImageUrl = `${getApiRootUrl()}/profileImage`
export const thumbnailImageUrl = `${getApiRootUrl()}/thumbnailImage`
export const removeImageUrl = `${getApiRootUrl()}/image`
export const uploadPdfUrl = `${getApiRootUrl()}/pdf`
export const bgImageUrl = `${getApiRootUrl()}/bgImage`
export const checkEUUrl = `${getApiRootUrl()}/isEU`

export const HIDE_WIDGETS_FEATURE_FLAG = 'hide_widget'
export const PAGES_CODES_FTUX = 'PAGES_CODES_FTUX'
export const POST_PUBLISH_SHARE_MODAL = 'fp_post_publish_share'
export const SIGNUP_FLOW_EXP = 'JS7i1DZmSO-bWeF-qVWj9Q'
export const MOBILE_HERO_LAYOUT_EXP = '-em3dn5wRzablm-DqPpjmQ'
export const HERO_LAYOUT_EXP = 'Tgdr8p2wS9Sxf3xRMXtGNQ'
export const TAB_EXP_ID = 'fsXdZ0gNRfi2tpZx5yXi8Q'
export const SLUG_LOCATION_EXP_ID = 'slugOnProfile'
export const WELCOME_LAYOUT = 'wtmqKM9MT8mqmzAmdIyDZQ'
export const SLUG_INPUT_BUTTON_EXP = 'cODBVCJAT3yB8b324pHTGQ'
export const CONTACT_SHARING = 'flowtag_contact_sharing'
export const MAX_SLUG_LENGTH = 30

type ThemeTypeName = 'Theme'

export const defaultUserTheme = {
  ...defaultUserThemeInput,
  backgroundImgUrl: null,
  __typename: 'Theme' as ThemeTypeName
}
export const defaultThemeInput = {
  primaryColor: DEFAULT_COLOR,
  style: 'light',
  profileImageMaskType: 'circle'
}

type UserTypeName = 'User'
export const defaultUserAttributes = {
  slugName: '',
  active: true,
  links: [],
  __typename: 'User' as UserTypeName,
  id: 'demo',
  profileImage: '',
  caption: '',
  email: '',
  shortUrl: '',
  share: true,
  displayName: '',
  theme: defaultUserTheme
}
type AccountTypeName = 'Account'
export const defaultAccountAttributes = {
  id: 'demo',
  email: '',
  pages: [],
  __typename: 'Account' as AccountTypeName
}

type LinkTypeName = 'Link'
export const defaultWidgetLink = {
  type: 'default',
  provider: LinkProvider.WIDGET,
  id: 'default-link',
  actionData: {},
  order: 0,
  embed: false,
  displayType: 'default',
  active: true,
  title: null,
  description: null,
  thumbNailImgUrl: null,
  linkTheme: null,
  __typename: 'Link' as LinkTypeName
}

export type FeatureLinkVariants = 'blm' | 'aapi'
export const FEATURED_LINKS = {
  blm: {
    title: 'Show your support for Black Lives Matter',
    description:
      'Enable this link to give visitors an easy way to support the cause and learn more about the justice movement.',
    cta: 'Add a Black Lives Matter Link',
    category: null,
    style: {
      background: '#2c3436',
      color: 'primary.white',
      ctaColor: 'primary.skyBlue'
    },
    link: {
      category: null,
      defaultFields: {
        imageUrl: '/icons/social-media/feature.png',
        link: 'https://flow.page/blmdonate',
        title: 'Support Black Lives Matter',
        meta: {
          provider: LinkProvider.FEATURE,
          type: 'blm',
          pattern: fullUrlPattern
        }
      },
      provider: LinkProvider.FEATURE,
      type: 'blm',
      displayText: 'a Black Lives Matter Link'
    }
  },
  aapi: {
    title: 'Show your support for the AAPI Community',
    description:
      'Enable this link to give visitors an easy way to support the cause and learn more about the Stop AAPI Hate movement.',
    cta: 'Add a Stop AAPI Hate link',
    category: null,
    style: {
      background: '#2c3436',
      color: 'primary.white',
      ctaColor: '#f4c33e'
    },
    link: {
      category: null,
      defaultFields: {
        imageUrl: '/images/aapi_feature.png',
        link: FLOWCODE_ROOT + '/page/aapi.community',
        title: 'Support the AAPI Community',
        meta: {
          provider: LinkProvider.FEATURE,
          type: 'aapi',
          pattern: fullUrlPattern
        }
      },
      provider: LinkProvider.FEATURE,
      type: 'aapi',
      displayText: 'a Stop AAPI Hate Link'
    }
  }
}

export const modalTitleStyles: Omit<CombinedTextProps, 'children'> = {
  variant: ['body/medium', 'body/medium', 'body/large'],
  fontWeight: '600'
}
