export interface ContactBody {
  add: { success: string; error: string }
  update: { success: string; error: string }
  delete: { success: string; error: string }
}

export const contactMessages = {
  email: {
    add: {
      success: 'Email added',
      error: 'Error adding Email'
    },
    update: {
      success: 'Email updated',
      error: 'Error updating Email'
    },
    delete: {
      success: 'Email deleted',
      error: 'Error deleting Email'
    }
  },
  text: {
    add: {
      success: 'Text added',
      error: 'Error adding Text'
    },
    update: {
      success: 'Text updated',
      error: 'Error updating Text'
    },
    delete: {
      success: 'Text deleted',
      error: 'Error deleting Text'
    }
  },
  call: {
    add: {
      success: 'Call added',
      error: 'Error adding Call'
    },
    update: {
      success: 'Call updated',
      error: 'Error updating Call'
    },
    delete: {
      success: 'Call deleted',
      error: 'Error deleting Call'
    }
  },
  location: {
    add: {
      success: 'Location added',
      error: 'Error adding Location'
    },
    update: {
      success: 'Location updated',
      error: 'Error updating Location'
    },
    delete: {
      success: 'Location deleted',
      error: 'Error deleting Location'
    }
  }
}

export const flowpageAutosaveMessaging = {
  editTemplate: {
    success: 'Your template has been updated',
    error: 'Error deleting your template'
  },
  deletePage: {
    success: 'Your page has been deleted',
    error: 'Error deleting your Flowpage'
  },
  editPageInfo: {
    success: 'Your changes have been saved',
    error: 'Error saving your Flowpage'
  },
  editTheme: {
    success: 'Your Flowpage design has been saved',
    error: 'Error saving your Flowpage design'
  },
  reorderLinks: {
    success: 'Your links have been reordered',
    error: 'Error reordering your links'
  },
  deleteLink: {
    success: 'Your link has been deleted',
    error: {
      general: 'Error deleting your link',
      templatePermission: 'You do not have access to the template to delete this link'
    }
  },
  editLink: {
    success: 'Your link has been edited',
    error: 'Error editing your link'
  },
  createLink: {
    success: 'Your link has been created',
    error: 'Error creating your link'
  },
  indexToggle: {
    success: 'Your indexing settings have been updated',
    error: 'Error updating your indexing settings'
  },
  createOrEditLinkTheme: {
    success: 'Your link style has been updated/created',
    error: 'Error updating/creating your link style'
  },
  resetLinkThemes: {
    success: 'Your link styles have been reset',
    error: 'Error resetting your link styles'
  }
}

export default {
  flowpage: {
    noAccount: "You Don't Have a Flowpage Account Yet",
    linkTaken: 'That link was just taken! Try another one...',
    copied: 'Flowpage URL copied to clipboard!',
    added: 'Flowpage created successfully!',
    deleted: 'Flowpage deleted successfully!',
    deleteLastAccountError: "Delete not allowed. This is your account's only Flowpage!"
  },
  theme: {
    success: 'Theme Saved!',
    error: 'Error saving theme',
    backgroundImage: {
      delete: {
        success: 'Background image deleted',
        error: 'Error deleting background image'
      }
    }
  },
  savePage: {
    success: 'Page Saved and Published!',
    error: 'Error publishing page'
  },
  savePageImages: {
    success: 'Page Images Uploaded!',
    error: 'Error uploading page images'
  },
  collection: {
    success: 'Contact Information Submitted',
    error: 'Error submitting contact information'
  },
  viewCollection: {
    empty: 'No contacts submitted yet',
    error: 'Error viewing CSV',
    script: 'Error loading google api script'
  },
  profile: {
    info: {
      update: {
        success: 'Profile updated',
        error: 'Error updating profile'
      }
    },
    image: {
      update: {
        success: 'Profile image updated',
        errors: {
          size: 'Uploaded file must be <10mb'
        }
      },
      delete: {
        success: 'Profile image deleted',
        error: 'Error deleting profile image'
      }
    },
    link: {
      add: 'Link added',
      update: 'Link updated',
      delete: 'Link deleted'
    },
    textBox: {
      delete: 'Text Box deleted'
    },
    referral: {
      copied: 'Invite link copied to clipboard!'
    }
  },

  resetPassword: {
    sent: 'Reset email sent  📨',
    success: 'Reset password successfully, sending you back to sign in',
    invalidLink: 'Invalid password reset link, please try again'
  }
}
