import styled from 'styled-components'

export const ColorPickerWrapper = styled.label<{
  value: string
  error: boolean
  disabled?: boolean
}>`
  height: 36px;
  width: 36px;
  margin-right: 6px;
  position: relative;
  box-sizing: content-box;

  input {
    opacity: 0;
  }

  .preview-color {
    border-radius: 4px;
    background: ${({ value, error }) =>
      !value || value === '#' || error
        ? `conic-gradient(
            from 180deg at 50% 50%,
            #8000FF -45.14deg,
            #FF00D6 0.58deg,
            #FF0000 46.76deg,
            #FF9900 89.37deg,
            #FAFF00 134.79deg,
            #00FF19 178.49deg,
            #00FFF0 226.57deg,
            #0038FF 271.08deg,
            #8000FF 314.86deg,
            #FF00D6 360.58deg
          );`
        : value};
    filter: ${({ disabled }) => (disabled ? 'grayscale(40%)' : 'unset')};
    width: 106%;
    height: 106%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .preview-color-border {
    pointer-events: none;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(17, 17, 17, 0.25);
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
