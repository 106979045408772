import { ChangeEvent, RefObject } from 'react'
import { DebouncedState } from 'use-debounce'
import { DialogProps } from '@dtx-company/design-system/src'
import { ReactCropperElement } from 'react-cropper'
import { UseFormReturn } from 'react-hook-form'

export enum MaskType {
  CIRCLE = 'circle',
  SQUARE = 'square',
  RECTANGLE = 'rectangle',
  IPHONEX = 'iphonex',
  FEATURED = 'featured',
  NO_IMAGE = 'noImage'
}

export type ImageEditorProps = DialogProps &
  ImageEditorControlsProps & {
    title: string
  }

export interface ImageEditorControlsProps {
  file?: File | string
  initialAspectRatio?: number
  hideMask?: boolean
  maskType?: MaskType
  hideDelete?: boolean
  useObjectURL?: boolean
  handleUploadImage: (imageUrl: string, maskType: MaskType, imageSize: number) => void
  handleDeleteImage: () => void
  onPositionChange?: () => void
  onSlide?: () => void
  onUpload?: () => void
  onSave?: () => void
}

export interface ImageEditorForm {
  image?: string
  imageSize: number
  scale: number
  selectedMaskType: MaskType
  mimeType: 'image/png' | 'image/jpeg' | 'image/svg'
}

export type ImageEditorContextType = UseFormReturn<ImageEditorForm> &
  Pick<
    ImageEditorControlsProps,
    | 'handleDeleteImage'
    | 'handleUploadImage'
    | 'hideDelete'
    | 'hideMask'
    | 'initialAspectRatio'
    | 'onPositionChange'
    | 'onSave'
    | 'onUpload'
    | 'useObjectURL'
  > & {
    cropperRef: RefObject<ReactCropperElement>
    defaultMaskType: MaskType
    onSlide: DebouncedState<() => void>
    onSubmit: (data: ImageEditorForm) => Promise<void>
    onZoomChange: (event: Cropper.ZoomEvent<HTMLImageElement>) => void
    onZoomInClick: () => void
    onZoomOutClick: () => void
    onReset: () => void
    onFileChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>
  }
