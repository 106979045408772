import { useCreateInFolderPath } from './useCreateInFolderPath'
import { useCreatePage } from '../../../hooks/useCreatePage'
import { useCurrentPage } from './currentPage'

export const useSavePage = (): (() => Promise<{ id: string } | void>) => {
  const currentPage = useCurrentPage()
  const createPage = useCreatePage()
  const createInFolderPath = useCreateInFolderPath()
  return async (): Promise<{ id: string } | void> => {
    return await createPage({
      slugName: currentPage?.slugName ?? '',
      pageType: currentPage?.pageType ?? undefined,
      domainServiceSubdomainId: currentPage?.domain?.domainServiceSubdomainId ?? undefined,
      path: createInFolderPath
    })
  }
}
