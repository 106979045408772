import { Box } from '../Box'
import { Text } from '../Text'
import { ToggleBase } from './styles'
import { ToggleProps } from '../../..'

export const Toggle = ({
  checked,
  disabled,
  id,
  label,
  labelProps,
  name,
  onBlur,
  onChange,
  onFocus,
  sizeVariant = 'lg',
  textComponent,
  textVariant = 'body/small',
  value,
  toggleInputProps,
  backgroundColor,
  ariaLabelledBy,
  ariaLabel,
  ...rest
}: ToggleProps): JSX.Element => {
  return (
    <Box alignItems="center" {...rest}>
      <ToggleBase
        sizeVariant={sizeVariant}
        backgroundColor={backgroundColor}
        disabled={disabled}
        aria-label={ariaLabel}
      >
        <input
          type="checkbox"
          value={value}
          name={name}
          id={id}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          aria-labelledby={ariaLabelledBy}
          aria-label={label}
          data-testid={toggleInputProps?.['data-testid']}
          {...toggleInputProps}
        />
        <span className="slider" />
      </ToggleBase>
      {label && (
        <Text variant={textVariant} component={textComponent} marginLeft="15px" {...labelProps}>
          {label}
        </Text>
      )}
    </Box>
  )
}
