import { TOptions } from 'exif-rotate-js/lib/options'
import { getBase64Strings } from 'exif-rotate-js/lib'

export function dataURItoBlob(dataURI: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this

  const bytes = dataURI.split(',')[1]
  const byteString =
    typeof window === 'undefined' ? Buffer.from(bytes, 'base64').toString('binary') : atob(bytes)

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)

  // create a view into the buffer
  const ia = new Uint8Array(ab)

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString })
  return blob
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const dataUrl = reader.result
      resolve(dataUrl as string)
    }
    reader.onerror = error => {
      reject(error)
    }
    reader.readAsDataURL(blob)
  })
}

// export async function readImageasUrl(image: File): Promise<string> {
//   return new Promise(resolve => {
//     const reader = new FileReader()

//     reader.onload = e => {
//       resolve(e.target.result as string)
//     }
//     reader.readAsDataURL(image)
//   })
// }

export function setImageSrc(document: HTMLDocument): void {
  document.querySelectorAll('img[data-lazysrc]').forEach(image => {
    const src = image.getAttribute('data-lazysrc')
    if (image instanceof HTMLImageElement && src) {
      image.src = src
    }
  })
}

const acceptedTypes = ['image/png', 'image/jpeg', 'image/webp']
type ImageType = TOptions['type']

const getMimeType = (type: string): TOptions['type'] =>
  acceptedTypes.includes(type) ? (type as ImageType) : 'image/png'
export async function loadImageWithEXIF(file: File): Promise<string | null> {
  try {
    const dataUrl = (
      await getBase64Strings([file], { type: getMimeType(file.type), maxSize: 3840 })
    )[0]
    const blob = dataURItoBlob(dataUrl)

    return URL.createObjectURL(blob)
  } catch {
    return null
  }
}
