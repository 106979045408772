import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { DropResult } from 'react-beautiful-dnd'
import { Link } from '@dtx-company/flow-codegen/src/page/generated.types'
import { PlaceholdersType } from '@dtx-company/inter-app/src/types/flowpage'
import { RootPageState } from '@dtx-company/inter-app/src/redux/types'
import {
  editContactPlaceholder,
  editDestinationPlaceholder,
  editSocialPlaceholder,
  reorderPlaceholders
} from '@dtx-company/inter-app/src/redux/slices/flowpageFunnelSlice'
import { moveListItem } from '../../../utils/object'
import { useDispatch } from 'react-redux'
import { usePageSelector } from '@dtx-company/inter-app/src/hooks/usePageSelector'

const getCallback = (
  type: keyof PlaceholdersType
): ActionCreatorWithPayload<
  {
    link?: Partial<Link>
  },
  string
> | null => {
  switch (type) {
    case 'contactInfo':
      return editContactPlaceholder
    case 'destination':
      return editDestinationPlaceholder
    case 'socialLink':
      return editSocialPlaceholder
    default:
      return null
  }
}

export function useEditPlaceholder(
  type: keyof PlaceholdersType
): (link?: Partial<Link>) => void | undefined {
  const callback = getCallback(type)
  const dispatch = useDispatch()

  const handleEdit = (link?: Partial<Link>): void => {
    if (callback) {
      dispatch(callback({ link }))
    }
  }
  return handleEdit
}

export function usePlaceholders(): PlaceholdersType | undefined {
  return usePageSelector((state: RootPageState) => state.fpFunnelReducer.placeholders)
}

export function useReorderPlaceholders(
  links?: [string, Partial<Link>][],
  track?: () => void
): (r: DropResult) => void {
  const dispatch = useDispatch()
  return function onDragEnd(result: DropResult): void {
    if (!result.destination || !links || result.destination.index === result.source.index) {
      return
    }
    const initialPosition = result.source.index
    const finalPosition = result.destination.index
    const reorderedLinks = moveListItem(
      links.map(link => link[1]),
      initialPosition,
      finalPosition
    )

    dispatch(reorderPlaceholders({ links: reorderedLinks }))
    track?.()
    return
  }
}
