import { RootState } from '@dtx-company/inter-app/src/redux/types'
import {
  hasFilledOutContact,
  hasFilledOutSocials
} from '@dtx-company/inter-app/src/redux/slices/pageEditor/convertPlaceholdersToLinks'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { usePlaceholders } from '../components/profile/Placeholders/hooks'
import { useSelector } from 'react-redux'

export function usePageHasLinks(): boolean {
  const currentPage = useCurrentPage()
  if (!currentPage?.links || currentPage?.links?.length === 0) {
    return false
  }
  if (currentPage?.links?.length === 1 && currentPage.links[0]?.type === 'contactInfo') {
    return false
  }
  return true
}

export function usePageHasAtLeastNLinks(N: number): boolean {
  const currentPage = useCurrentPage()
  if (!currentPage?.links || currentPage?.links?.length < N) {
    return false
  }
  if (currentPage?.links?.length === N) {
    for (const link of currentPage?.links ?? {}) {
      if (link?.type && link?.type !== 'contactInfo') {
        return true
      }
    }
    return false
  }
  return true
}

export function usePageHasBio(): boolean {
  const currentPage = useCurrentPage()
  return Boolean(currentPage?.displayName || currentPage?.profileImage || currentPage?.caption)
}

export function usePageHasPlaceholders(): boolean {
  const placeholders = usePlaceholders()
  const hasStandardLink =
    placeholders?.destination?.actionData?.link && placeholders?.destination?.title
  const hasContact = hasFilledOutContact(placeholders?.contactInfo)
  const hasSocials = hasFilledOutSocials(placeholders?.socialLink)
  return hasContact || hasStandardLink || hasSocials
}
export function usePageCanBePublished(): boolean {
  const { requireTwoLinksToPublish } = useSelector(
    (state: RootState) => state.appReducer.optimizeTestReducer
  )
  const pageHasPlaceholders = usePageHasPlaceholders()
  const pageHasLinks = usePageHasLinks()
  const pageHasAtLeastTwoLinks = usePageHasAtLeastNLinks(2)
  const pageHasEnoughLinks = requireTwoLinksToPublish
    ? pageHasAtLeastTwoLinks || pageHasPlaceholders
    : pageHasLinks || pageHasPlaceholders
  return pageHasEnoughLinks
}
