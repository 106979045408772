import { Link } from '@dtx-company/flow-codegen/src/page/generated.types'
import { PlaceholdersType } from '../../../types/flowpage'
import { defaultWidgetLink } from '@dtx-company/app/shared/page/src/constants'
import { v4 as uuid } from 'uuid'

export function hasFilledOutSocials(link?: Partial<Link>): boolean {
  if (!link) return false
  const actionData = link?.actionData

  return (
    actionData?.instagram?.link ||
    actionData?.tiktok?.link ||
    actionData?.snapchat?.link ||
    actionData?.twitter?.link ||
    actionData?.linkedin?.link
  )
}

export function hasFilledOutContact(link?: Partial<Link>): boolean {
  if (!link) return false
  const actionData = link?.actionData

  return actionData?.call || actionData?.text || actionData?.email
}

type SocialActionData = {
  title: string
  description: string
  order: number
  link: string
}

export function convertPlaceholdersToLinks(
  placeholders: PlaceholdersType | null,
  linksLength: number
): (Link | null)[] {
  if (!placeholders) return [null]
  const placeholdersList = Object.entries(placeholders)
  let curLinksAdded = 0
  if (!placeholdersList || placeholdersList?.length === 0) return [null]
  return placeholdersList?.map(([type, link]) => {
    const baseLink = {
      ...defaultWidgetLink,
      order: (link?.order ?? 0) + linksLength + curLinksAdded
    }
    switch (type) {
      case 'socialLink':
        if (hasFilledOutSocials(link)) {
          const { iconSize, iconStyle, ...rest } = link?.actionData ?? {}
          const resolvedActionData = Object.entries(rest)?.reduce(
            (acc, curr): Record<string, SocialActionData> => {
              const [provider, link] = curr as [string, SocialActionData]
              return {
                ...acc,
                [provider]: {
                  ...link,
                  order: link?.link ? link.order : -1
                }
              }
            },
            {}
          )
          curLinksAdded += 1

          return {
            ...baseLink,
            ...link,
            actionData: { ...resolvedActionData, iconSize, iconStyle },
            type: 'socialLink',
            id: uuid()
          }
        } else {
          return null
        }
      case 'destination':
        if (link?.actionData?.link) {
          curLinksAdded += 1
          return {
            ...baseLink,
            provider: 'link',
            displayType: 'default',
            ...link,
            id: uuid()
          }
        } else {
          return null
        }
      case 'contactInfo':
        if (hasFilledOutContact(link)) {
          curLinksAdded += 1
          return {
            ...baseLink,
            type: 'contactInfo',
            displayType: 'default',
            ...link,
            id: uuid()
          }
        } else {
          return null
        }
      default:
        return null
    }
  })
}
