import { Box } from '../../atoms/Box/index'
import { ChangeEventHandler, FC, useEffect, useState } from 'react'
import { ColorPickerWrapper } from './styles'
import { Input } from '../../atoms/Input/index'
import { Text } from '../../atoms/Text/index'
import { WidthProps } from 'styled-system'
import { useDebouncedCallback } from 'use-debounce'
import { validateHexInput } from '@dtx-company/true-common/src/utils/strings'

export interface ColorInputHexProps {
  label?: string
  color: string
  disabled?: boolean
  onColorChange: (hexColor: string) => void
  width?: WidthProps['width']
}

const isValidHex = (hex: string): boolean => hex.length === 7 && validateHexInput(hex)

/*
 * A color picker accepting a HEX color string or triggering the native color picker.
 * Manages its own state, including error and reports only valid and complete hex values
 * to its parent via `onColorChange`.
 * Used in Flowpage and Flowcode color options.
 */
export const ColorInputHex: FC<ColorInputHexProps> = ({
  label,
  color,
  disabled,
  onColorChange,
  width,
  ...rest
}) => {
  const [localColor, _setLocalColor] = useState('')
  const [error, setError] = useState('')

  // input[type="color"] needs a debounced callback to listen for changes because it
  // calls its onChange handler too often which  makes the picker slow and laggy (not a good UX)
  const handleColorPicker: ChangeEventHandler<HTMLInputElement> = useDebouncedCallback(e => {
    const inputColor = e.target.value.toUpperCase().replace(/##+/, '#')
    _setLocalColor(inputColor)

    if (isValidHex(inputColor)) onColorChange(inputColor)
  }, 500)

  const handleColorTextField: ChangeEventHandler<HTMLInputElement> = e => {
    const inputColor = e.target.value.toUpperCase().replace(/##+/, '#')
    const formattedColor = inputColor.startsWith('#') ? inputColor : `#${inputColor}`
    _setLocalColor(formattedColor)
    if (isValidHex(formattedColor)) onColorChange(formattedColor)
  }

  // Watch for changes on `color` prop and update localColor if changed
  // e.g. the user selects a color via ColorChip and we want the hex field to
  // update with the chosen color
  useEffect(() => {
    if (color !== localColor) {
      if (color === '') _setLocalColor('')
      if (isValidHex(color)) {
        _setLocalColor(color.toUpperCase())
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [color])

  useEffect(() => {
    if (localColor) {
      if (isValidHex(localColor)) error && setError('')
      else {
        setError('Invalid hex color')
      }
    }
  }, [localColor])

  return (
    <Box flexDirection="column" width={width ?? '100%'}>
      <Input
        controlled
        label={label}
        type="text"
        value={localColor}
        placeholder="#HEX"
        onChange={handleColorTextField}
        labelProps={{ marginBottom: '8px' }}
        disabled={disabled}
        width="100%"
        maxWidth="unset"
        inputProps={{
          spellCheck: false,
          autoComplete: 'off',
          autoCorrect: 'off',
          maxLength: 9,
          'aria-label': `${label}-color-input`
        }}
        startAdornmentWrapperProps={{ left: '3px', top: '-1px' }}
        startAdornment={
          <ColorPickerWrapper value={localColor} error={Boolean(error)} disabled={disabled}>
            <input
              // value={} Left uncontrolled because it can be laggy or break in diff browsers. See sc-49137
              onChange={handleColorPicker}
              type="color"
              disabled={disabled}
              aria-label="Color picker"
            />
            <span data-testid="preview-color" className="preview-color"></span>
            <span className="preview-color-border"></span>
          </ColorPickerWrapper>
        }
        error={Boolean(error)}
        {...rest}
      />
      {error && (
        <Text color="status.errorDark" variant="body/small" pt="4px">
          {error}
        </Text>
      )}
    </Box>
  )
}
